import {
  serverRoutes,
  templateServerRouteBySlug,
} from "client-server-shared/config/routes";
import { siteConfig } from "client-server-shared/config/siteConfig";
import { LogoIconOnly } from "components/logo";
import { NextLink } from "components/next-link";
import { Avatar } from "components/ui/avatar";
import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Container } from "components/ui/container";
import { List, ListItem, ListSubHeader } from "components/ui/list/List";
import { Description, Typography } from "components/ui/Typography";
import { MainBackgroundWrapper } from "./shared";
import { MetaColorIcon } from "client-server-shared/icons/meta-color-icon";
import { TwitterColorIcon } from "client-server-shared/icons/twitter-color-icon";
import Affilate from "./affliate";
import { trackClickOnSocialMediaLink } from "utils/tracking/common";
import { useTranslation } from "components/translate";
import { isCn } from "client-server-shared/constants";

interface ListSectionProps {
  headerText: string;
  list: { text: string; to: string }[];
}

const VerificationItem = ({ heading, text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "12px",
        textAlign: "center",
      }}
    >
      <Description
        sx={{
          color: "text.white",
        }}
      >
        {heading}
      </Description>
      <Description
        sx={{
          color: "text.white",
        }}
      >
        {text}
      </Description>
    </Box>
  );
};
const VerificationPurpuses = () => {
  return (
    <Box
      sx={{
        display: "flex",
        columnGap: "12px",
        justifyContent: "center",
        margin: "12px auto",
      }}
    >
      <VerificationItem heading="Registered entity name" text="YZplanet Oy" />
      <VerificationItem heading="Tax ID" text="FI31095196" />
    </Box>
  );
};

const useData = () => {
  const { t } = useTranslation("common");

  const productLists = [
    {
      text: t("AI Text Editor"),
      to: serverRoutes.editor,
    },
    {
      text: "Bulk Content Generator",
      to: serverRoutes.bulkContentCreation,
    },
    {
      text: t("Chat By Junia.ai"),
      to: serverRoutes.chat,
    },
    {
      text: t("Templates"),
      to: serverRoutes.templates,
    },
    {
      text: "Free AI Tools",
      to: serverRoutes.tools,
    },
    {
      text: t("Junia Chat vs ChatGPT"),
      to: serverRoutes.chatgpt,
    },
    {
      text: t("Pricing"),
      to: serverRoutes.pricing,
    },
    {
      text: t("Testimonials"),
      to: serverRoutes.testimonials,
    },
  ].filter(Boolean);
  const resourcesList = [
    {
      text: t("Guides"),
      to: serverRoutes.docs,
    },
    {
      text: t("Blogs"),
      to: serverRoutes.blog,
    },
    {
      text: t("Personalized Writing"),
      to: serverRoutes.styleMatch,
    },
    {
      text: t("Blog Post Generator"),
      to: templateServerRouteBySlug("blog"),
    },
    {
      text: t("Blog Images Generator"),
      to: serverRoutes.blogImageGenerator,
    },
    {
      text: t("AI Internal Linking"),
      to: serverRoutes.aiInternalLinking,
    },
    {
      text: t("AI Keyword Research"),
      to: serverRoutes.aiKeywordResearch,
    },
    {
      text: t("Multilingual Bulk Translate & Rewriter"),
      to: serverRoutes.bulkTranslate,
    },
  ];

  const supportList = [
    {
      text: t("About us"),
      to: serverRoutes.about,
    },
    {
      text: t("Terms"),
      to: serverRoutes.terms,
    },
    {
      text: t("Security"),
      to: serverRoutes.security,
    },
    {
      text: t("Cookie Policy"),
      to: serverRoutes.cookiePolicy,
    },
    {
      text: t("Privacy Policy"),
      to: serverRoutes.privacyPolicy,
    },
    {
      text: t("Junia Affiliate"),
      to: serverRoutes.affiliate,
    },
    {
      text: t("Contact us"),
      to: serverRoutes.contact, // "mailto:contact@junia.ai", // serverRoutes.contact,
    },
  ];
  return { productLists, resourcesList, supportList };
};

const ListSection = ({ headerText, list }: ListSectionProps) => {
  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      subheader={
        <ListSubHeader
          disableGutters
          sx={{
            background: "none",
          }}
        >
          <Typography variant="body1" fontWeight={600} color="text.white">
            {headerText}
          </Typography>
        </ListSubHeader>
      }
    >
      {list.map((item) => {
        return (
          <ListItem
            key={item.text}
            disableGutters
            className="with-hover-underline-effect-a"
            sx={{
              paddingTop: "4px",
              paddingBottom: "4px",
              letterSpacing: ".5px",
            }}
          >
            <Typography
              component={NextLink}
              sx={{
                textDecoration: "none",
                fontSize: "14px",
                fontWeight: 300,
              }}
              href={item.to}
              color="text.white"
            >
              {item.text}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};

const bottoms = [
  {
    text: "Modern Slavery Statement",
    to: serverRoutes.modernSlaveryStatement,
  },
  {
    text: "EDI Policy",
    to: serverRoutes.ediPolicy,
  },
  {
    text: "Accessibility",
    to: serverRoutes.accessibility,
  },
  {
    text: "Code Of Ethics",
    to: serverRoutes.codeOfEthics,
  },
  {
    text: "Editorial Policy",
    to: serverRoutes.editorialPolicy,
  },
  {
    text: "Complaints Policy",
    to: serverRoutes.complaintsPolicy,
  },
  {
    text: "Guest Posting Policy",
    to: serverRoutes.guestPosting,
  },
];

const FooterBottom = () => {
  return (
    <Box
      sameLine
      alignCenter
      marginTop={48}
      sx={{
        justifyContent: "center",
        columnGap: "48px",
      }}
    >
      <Typography color="text.white">@ 2024 Junia AI</Typography>
      <Box>
        <IconButton
          component="a"
          href={siteConfig.links.facebook}
          target="__blank"
          rel="noopener noreferrer nofollow"
          aria-label="Facebook"
          onClick={() => {
            trackClickOnSocialMediaLink({
              type: "facebook",
            });
          }}
        >
          <Avatar>
            <MetaColorIcon />
          </Avatar>
        </IconButton>

        <IconButton
          component="a"
          rel="noopener noreferrer nofollow"
          href={siteConfig.links.twitter}
          target="__blank"
          onClick={() => {
            trackClickOnSocialMediaLink({
              type: "twitter",
            });
          }}
          aria-label="Twitter"
        >
          <Avatar>
            <TwitterColorIcon />
          </Avatar>
        </IconButton>
        {/*
                  <IconButton
          component="a"
          href={siteConfig.links.linkedin}
          target="__blank"
        >
          <Avatar>
            <LinkedinColorIcon />
          </Avatar>
        </IconButton>
        <IconButton
          component="a"
          href={siteConfig.links.youtube}
          target="__blank"
        >
          <Avatar>
            <YoutubeColorIcon />
          </Avatar>
        </IconButton>
          */}
      </Box>
    </Box>
  );
};

const Footer = () => {
  const { productLists, resourcesList, supportList } = useData();
  const { t } = useTranslation("common");
  return (
    <MainBackgroundWrapper component="footer">
      <Container
        maxWidth="xl"
        sx={{
          padding: {
            xs: "80px 30px",
            sm: "80px",
            md: "80px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            rowGap: "24px",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexBasis: "15%",
              rowGap: "24px",
              width: "100%",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "initial",
              },
            }}
          >
            <LogoIconOnly width={65} />
          </Box>

          <Box
            component="section"
            sx={{
              display: "flex",
              flexGrow: 1,
              flexWrap: "wrap",
              rowGap: "48px",
              columnGap: "12px",
              justifyContent: {
                xs: "space-between",
                sm: "space-between",
                md: "space-between",
                lg: "space-between",
                xl: "space-between",
              },
            }}
          >
            <ListSection headerText={t("Product")} list={productLists} />

            <ListSection headerText={t("Resources")} list={resourcesList} />

            <ListSection headerText={t("Company")} list={supportList} />
          </Box>
        </Box>
        <Affilate />
        <FooterBottom />
        {isCn() ? null : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              margin: "12px auto",
              flexWrap: "wrap",
              rowGap: "12px",
            }}
          >
            {bottoms.map((item) => {
              return (
                <Typography
                  component={NextLink}
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    fontWeight: 300,
                  }}
                  href={item.to}
                  color="text.white"
                >
                  {item.text}
                </Typography>
              );
            })}
          </Box>
        )}
        <VerificationPurpuses />
      </Container>
    </MainBackgroundWrapper>
  );
};

export default Footer;
