import type { Session } from "@supabase/supabase-js";
import { isCn, adminUserIds } from "client-server-shared/constants";
import { drawerWidth } from "./modules/themes/sytle-constants";
import { SubscriptionWithPrice } from "client-server-shared/types/payment";

export const collectionPageId = "collection-page";

export const mainPageWidth = `calc(100% - ${drawerWidth}px)`;

export { adminUserIds };

let markdownFileName = "content.md";
let custom = "custom";

if (isCn()) {
  custom = "自定义";
}
let session: Session | null = null;
let activePlan: SubscriptionWithPrice | null = null;

const setGlobalSession = (newSession: Session) => {
  session = newSession;
};

const isUserAdminGlobal = () => {
  if (session) {
    return adminUserIds.includes(session.user?.id || "");
  }
  return false;
};

const getGlobalSession = () => {
  return session;
};

const setGlobalActivePlan = (newActivePlan: SubscriptionWithPrice | null) => {
  activePlan = newActivePlan;
};
const getGlobalActivePlan = () => {
  return activePlan;
};

export {
  custom,
  setGlobalActivePlan,
  getGlobalActivePlan,
  getGlobalSession,
  setGlobalSession,
  isUserAdminGlobal,
};
