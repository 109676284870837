import { enUrl, siteConfig } from "client-server-shared/config/siteConfig";
import { isCn } from "client-server-shared/constants";
import { DefaultSeo, NextSeo } from "next-seo";

const buildAlternateLinks = (path = "") => {
  return [
    {
      rel: "alternate",
      href: `${siteConfig.alternateUrl}${path}`,
      hrefLang: siteConfig.alternateHrefLang,
    },
  ];
};

const indexPageConfig = {
  description: siteConfig.description,
  defaultTitle: siteConfig.title,
  canonical: siteConfig.url,
  openGraph: {
    type: "website",
    url: siteConfig.url,
    title: siteConfig.title,
    description: siteConfig.description,
    siteName: siteConfig.name,
    images: [
      {
        url: `${siteConfig.url}${siteConfig.openGraphImage}`,
        width: 1200,
        height: 600,
        alt: `${siteConfig.name} - AI powered content writer`,
      },
    ],
  },
};

const defaultConfigs = {
  additionalLinkTags: [
    {
      rel: "shortcut icon",
      type: "image/png",
      sizes: "96x96",
      href: siteConfig.favicon,
    },
    {
      rel: "apple-touch-icon",
      href: siteConfig.logo.url,
      sizes: "76x76",
    },
    {
      rel: "manifest",
      href: "/manifest.json",
    },
    {
      rel: "alternate",
      href: `${enUrl}`,
      key: "x-default",
      hrefLang: "x-default",
    },
    //  ...buildAlternateLinks(),
  ],
  additionalMetaTags: [
    {
      charSet: "utf-8",
    },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1, shrink-to-fit=no",
    },
    {
      name: "x-ua-compatible",
      content: "ie=edge",
    },
    {
      name: "theme-color",
      content: "#ffffff",
    },
    {
      name: "baidu-site-verification",
      content: isCn() ? "codeva-NcM2GMofQ4" : "codeva-SdKZgOxlME",
    },
  ],
  twitter: {
    handle: "@Junia_ai",
    site: "@Junia_ai",
    cardType: "summary_large_image",
  },
};

export const IndexPageHeadTags = (props = {}) => {
  return <NextSeo {...indexPageConfig} {...props} />;
};

export const DefaultHeadTags = () => {
  return <DefaultSeo {...defaultConfigs} />;
};

export const PageHeadTags = ({
  pageLink,
  title,
  description,
  ogImage,
  noAlternative,
  ...rest
}: {
  ogImage?: string;
  pageLink: string;
  title: string;
  description: string;
  noAlternative?: boolean;
}) => {
  const url = `${siteConfig.url}${pageLink}`;
  const ogImageUrl = ogImage
    ? `${siteConfig.url}/${ogImage}.png`
    : `${siteConfig.url}/og.png`;
  return (
    <NextSeo
      canonical={url}
      title={title}
      description={description}
      /*
      additionalLinkTags={
        noAlternative ? [] : [...buildAlternateLinks(pageLink)]
      }
      */
      openGraph={{
        type: "website",
        url: url,
        title: title,
        description: description,
        siteName: siteConfig.name,
        images: [
          {
            url: ogImageUrl,
            width: 1200,
            height: 600,
            alt: title,
          },
        ],
      }}
      {...rest}
    />
  );
};
