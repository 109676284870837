import type { BulkGenerateJobConfigPayload } from "./types/workflow";
export const locale = process.env.NEXT_PUBLIC_LOCALE;

export const isCn = () => locale === "cn";

let lang = "English";

if (isCn()) {
  lang = "Chinese";
}

export const adminUserIds = [
  "f2f98d5e-63c4-412b-92cd-f82429517add",
  "e5afbc74-c7dc-48ce-a032-91ae0bd010a8",
  "9223b844-e3a0-42b6-9938-0ecffedbade2",
  "ad123253-6dc1-41bd-b2f4-1d944eb68760",
  "e5763b7e-00a9-487e-8bc6-ed135b48f60b",
  "bc58f479-a905-41f8-b1fa-d56dc13a8e8d",
  "18d32214-afe4-4bec-8652-8e6d1b062e19",
  "e8ea8e1f-482a-4fb1-b14a-8a0e53a999eb",
  "471d1add-63d1-4d19-88dd-a40fb3e22f5d",
  "2a628fe9-bde4-430c-97f9-7bdf6e47afc0",
];

export const withSpecialUserId = (userId?: string) => {
  return userId;
};
export const withSpecialAccessToBulkGenerator = (userId?: string) => {
  const ids = [
    "161254b5-0d46-4f87-916b-235d845cd081",
    "4b5ab51c-d9f7-4ba2-8b42-23911e8fa317",
    "6360de9b-b92c-487b-8f45-1aedebaa9609",
    "9003c66d-e960-4742-bcba-01e233db4c85",
  ];
  return ids.includes(userId || "");
};
export { lang };

export const languagesExampleByLanguage = {
  English: "English",
  "English (UK)": "",
  Arabic: "العربية",
  Bengali: "বাংলা",
  Bulgarian: "български",
  Chinese: "中文",
  "Chinese(traditional)": "中文(繁體)",
  Croatian: "hrvatski",
  Czech: "čeština",
  Danish: "dansk",
  Dutch: "Nederlands",
  Estonian: "eesti keel",
  Finnish: "suomi",
  French: "français",
  Georgian: "ქართული",
  German: "Deutsch",
  Greek: "Ελληνικά",
  Hebrew: "עִבְרִית‎",
  Hindi: "हिन्दी",
  Hungarian: "Magyar",
  Indonesian: "Bahasa Indonesia",
  Italian: "Italiano",
  Japanese: "日本語",
  Korean: "한국어",
  Latvian: "Latviešu valoda",
  Lithuanian: "Lietuvių kalba",
  Malay: "Bahasa Melayu",
  Maltese: "Malti",
  Norwegian: "norsk (bokmål)",
  Polish: "polski",
  Portuguese: "português",
  Romanian: "română",
  Russian: "русский",
  Serbian: "српски језик",
  Slovak: "slovenčina",
  Slovenian: "slovenščina",
  Spanish: "español",
  Swedish: "svenska",
  Tamil: "தமிழ்",
  Thai: "ไทย",
  Turkish: "Türkçe",
  Ukrainian: "українська мова",
  Vietnamese: "Tiếng Việt",
};

export const isSupportedLanguage = (language?: string) => {
  return language && !!languagesExampleByLanguage[language];
};

export const getTypographicRuleByLanguage = (language?: string) => {
  const rules = {
    French: `, Suivez ces règles typographiques spécifiques :
1. Capitalisation dans les titres : capitalisez uniquement la première lettre du premier mot. Par exemple, 'Conception et Types d'Escaliers Suspendus' doit être modifié en 'Conception et types d'escaliers suspendus'.
2. Majuscule après un deux-points : après un deux-points (':'), la première lettre du mot suivant doit être en minuscule, sauf s'il s'agit d'un nom propre ou si cela introduit une citation, une liste ou un discours direct. Par exemple, 'Escalier flottant: Un type' doit être changé en 'Escalier flottant : un type'.
3. Espaces insécables : incluez un espace insécable avant et après les points-virgules, les deux-points, les tirets, les points d'interrogation et les points d'exclamation.
4. Guillemets français : utilisez les guillemets français (« et ») avec un espace avant et après chacun. Pour les citations à l'intérieur des citations, utilisez les guillemets de style anglais.\n`,
    "Chinese(traditional)": `, 確保用繁體中文書寫. \n`,
    "English (UK)": `, Ensure the content is written in British English. \n`,
    Danish: `, Følg disse typografiske regler:
1. Kapitalisering i overskrifter: Kapitalisér kun det første ord i overskriften samt eventuelle egennavne. For eksempel skal 'Dette Er En Overskrift' ændres til 'Dette er en overskrift'.
2. Ingen kapitalisering efter kolon: Efter et kolon (':') bør det følgende ord ikke være kapitaliseret, medmindre det er et egennavn eller indleder et citat, en liste eller en direkte tale. For eksempel skal 'Følgende: En Eksempeltekst' ændres til 'Følgende: en eksempeltekst'.
3. Brug af anførselstegn: Brug dobbelte anførselstegn (" ") for citater. Enkelte anførselstegn (' ') bruges til citater inden for citater.\n`,
  };
  return rules[language || ""] || "";
};

export const languageToLocale = {
  English: "en-US",
  "English (UK)": "en-GB",
  Arabic: "ar-SA",
  Bengali: "bn-BD",
  Bulgarian: "bg-BG",
  Chinese: "zh-CN",
  "Chinese(traditional)": "zh-TW",
  Croatian: "hr-HR",
  Czech: "cs-CZ",
  Danish: "da-DK",
  Dutch: "nl-NL",
  Estonian: "et-EE",
  Finnish: "fi-FI",
  French: "fr-FR",
  Georgian: "ka-GE",
  German: "de-DE",
  Greek: "el-GR",
  Hebrew: "he-IL",
  Hindi: "hi-IN",
  Hungarian: "hu-HU",
  Indonesian: "id-ID",
  Italian: "it-IT",
  Japanese: "ja-JP",
  Korean: "ko-KR",
  Latvian: "lv-LV",
  Lithuanian: "lt-LT",
  Malay: "ms-MY",
  Maltese: "mt-MT",
  Norwegian: "nb-NO",
  Polish: "pl-PL",
  Portuguese: "pt-PT", // pt-BR for Brazilian Portuguese
  Romanian: "ro-RO",
  Russian: "ru-RU",
  Serbian: "sr-RS",
  Slovak: "sk-SK",
  Slovenian: "sl-SI",
  Spanish: "es-ES", // es-MX for Mexican Spanish
  Swedish: "sv-SE",
  Tamil: "ta-IN",
  Thai: "th-TH",
  Turkish: "tr-TR",
  Ukrainian: "uk-UA",
  Vietnamese: "vi-VN",
};

export const languageToLocalePlain = {
  English: "en",
  "English (UK)": "en",
  Arabic: "ar",
  Bengali: "bn",
  Bulgarian: "bg",
  Chinese: "zh",
  "Chinese(traditional)": "zh",
  Croatian: "hr",
  Czech: "cs",
  Danish: "da",
  Dutch: "nl",
  Estonian: "et-EE",
  Finnish: "fi",
  French: "fr",
  Georgian: "ka",
  German: "de",
  Greek: "el",
  Hebrew: "he",
  Hindi: "hi",
  Hungarian: "hu",
  Indonesian: "id",
  Italian: "it",
  Japanese: "ja",
  Korean: "ko",
  Latvian: "lv",
  Lithuanian: "lt",
  Malay: "ms",
  Maltese: "mt",
  Norwegian: "nb",
  Polish: "pl-PL",
  Portuguese: "pt-PT",
  Romanian: "ro",
  Russian: "ru",
  Serbian: "sr",
  Slovak: "sk",
  Slovenian: "sl",
  Spanish: "es",
  Swedish: "sv",
  Tamil: "ta",
  Thai: "th",
  Turkish: "tr",
  Ukrainian: "uk",
  Vietnamese: "vi",
};

interface LanguageOption {
  value: string;
  label: string;
  example?: string;
}

let languageOptions: LanguageOption[] = [
  "English",
  "English (UK)",
  "Arabic",
  "Bengali",
  "Bulgarian",
  "Chinese",
  "Chinese(traditional)",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Estonian",
  "Finnish",
  "French",
  "Georgian",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Korean",
  "Latvian",
  "Lithuanian",
  "Malay",
  "Maltese",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swedish",
  "Tamil",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
].map((item) => {
  return {
    value: item,
    label: item,
    example: languagesExampleByLanguage[item],
  };
});

export const supportedSEOLanguages = [
  "English",
  "English (UK)",
  "German",
  "Dutch",
  "French",
  "Spanish",
  "Italian",
  "Portuguese",
  "Russian",
  "Polish",
  "Swedish",
  "Hungarian",
  "Indonesian",
  "Arabic",
  "Hebrew",
  "Farsi",
  "Turkish",
  "Norwegian",
  "Czech",
  "Slovak",
  "Greek",
  "Japanese",
];

export const shouldPopulateLanguage = (lang?: string) =>
  lang && lang !== "English";

export { languageOptions };

export const imageRestoreStorageBucket = "user-generated-images";

export const otherUserAssets = "user-other-assets";

export const musicBucket = "user-generated-music";

export const publicBucket = "public";

export const allUserBuckets = [
  imageRestoreStorageBucket,
  otherUserAssets,
  musicBucket,
];

export const constructPublicImageUrl = (path: string) => {
  return `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/${path}`;
};

export const generateEndingWord = "595e1773-16b3";
export const streamJsonSeparator = "19-b3";
export const streamVariationSeparator = "b3-19";
export const pageNumberSeparator = "b3ba";

export const textEnhancementOperation = {
  paraphrase: "paraphrase",
  lengthen: "lengthen",
  translate: "translate",
  shorten: "shorten",
  summarize: "summarize",
  write_outline: "write_outline",
  synonyms: "synonyms",
};

export const interactiveTextOperation: any = {
  expand: "op-expand",
  shorten: "op-shorten",
  paraphrase: "op-paraphrase",
  translate: "op-translate",
  summary: "op-summary",
  improveSEO: "op-improve-seo",
  writeMore: "op-write-more",
  command: "op-command",
  conclusion: "op-conclusion",
  spellingGrammer: "op-spelling-grammer",
  formatting: "op-formatting",
  changeTone: "op-change-tone",
  simpleLanguage: "op-simple-language",
  addDepth: "op-add-depth",
  addInternalLinks: "op-add-internal-links",
  readability: "op-readability",
  improve: "op-improve",
  metaDescription: "op-meta-description",
  metaTitle: "op-meta-title",
  incopreateKeywords: "op-incopreate-keywords",
  prompt: "op-prompt",
  introduction: "op-introduction",
  humanize: "op-humanize",
  slogan: "op-slogan",
  prosAndCons: "op-pros-and-cons",
  reword: "op-reword",
  simplifyText: "op-simplify-text",
  activeToPassiveVoice: "op-active-to-passive-voice",
  passiveToActiveVoice: "op-passive-to-active-voice",
  bookIdea: "op-book-idea",
  bookTitle: "op-book-title",
  nicheIdeas: "op-niche-ideas",
  valueProposition: "op-value-proposition",
  proposal: "op-proposal",
  resignationLetter: "op-resignation-letter",
  callToAction: "op-call-to-action",
  businessName: "op-business-name",
  hook: "op-hook",
  acronyms: "op-acronyms",
  emailReply: "op-email-reply",
  customerResponse: "op-customer-response",
  redditComment: "op-reddit-comment",
  twitterReply: "op-twitter-reply",
  externalLinks: "op-external-links",
};

export const getLanguagePrompt = (language?: string) => {
  const selectedExampleLanguage =
    language && languagesExampleByLanguage[language]
      ? `(${languagesExampleByLanguage[language]})`
      : "";
  if (!language || !selectedExampleLanguage || language === "English") {
    return "";
  }
  return `${language} language${selectedExampleLanguage}`;
};

export const interactiveTextOperations = Object.values(
  interactiveTextOperation
);

export const delle3Sizes = ["1024x1024", "1024x1792", "1792x1024"];
export const dalle2Sizes = ["256x256", "512x512"];
export const imageSizes = [
  "1200x1200",
  ...delle3Sizes,
  ...dalle2Sizes,
  "768x768",
  // "1300x550",
];

// export const delleSizes = [imageSizes[0], imageSizes[3]];
// export const allSdSizes = [imageSizes[1], imageSizes[2]];
export const allDelleSizes = [imageSizes[0], imageSizes[1], imageSizes[3]];
export const dalleStyles = ["Animal", "Blog"];
export const twoYearInSeconds = 315360000;

export const getHasNegativePrompt = (config: Record<string, any>) => {
  const hasNegativePrompt =
    typeof config.negativePrompt === "string" &&
    config.negativePrompt &&
    config.negativePrompt.length > 0;
  return hasNegativePrompt;
};

export const oneDayInSeconds = 86400;

export enum FromType {
  fromChatFileUpload = "chat-file-upload",
  info = "info-base",
  webPage = "web-page",
}

export enum IntegrationType {
  Webflow = "webflow",
  Medium = "medium",
  WordPress = "wordpress",
  API = "api",
  Shopify = "shopify",
}

let defaultCollectionName = "Untitled Collection";
let defaultPostName = "Untitled Post";
let markdownFileName = "content.md";
let custom = "custom";

if (isCn()) {
  custom = "自定义";
  markdownFileName = "内容.md";
  defaultCollectionName = "无名文档";
  defaultPostName = "无名文章";
}

export const getBulkGenerateCost = (config: { size: string }) => {
  if (config.size === "short") {
    return 3000;
  }
  if (config.size === "medium") {
    return 4000;
  }
  return undefined;
};

export const getBulkGenerateCostWorkflows = (config: { size: string }) => {
  if (config.size === "short") {
    return 1;
  }
  if (config.size === "medium") {
    return 1;
  }
  return undefined;
};

export const getBulkGenerateAmount = (config: BulkGenerateJobConfigPayload) => {
  const length =
    config.generationMode === "urls"
      ? config.urls.length
      : config.titles.length;
  return length;
};

export const indexingCost = 1500;

export const calculateBulkGenerateCostsWorkflows = (
  config: BulkGenerateJobConfigPayload
) => {
  const length = getBulkGenerateAmount(config);

  const perCost = getBulkGenerateCostWorkflows({ size: config.size });
  if (typeof perCost === "number" && length > 0) {
    return perCost * length;
  }
  return undefined;
};

const calculateBulkGenerateCosts = (
  config: BulkGenerateJobConfigPayload & { autoIndex?: boolean }
) => {
  const length = getBulkGenerateAmount(config);

  const perCost = getBulkGenerateCost({ size: config.size });
  if (typeof perCost === "number" && length > 0) {
    if (config.autoIndex) {
      return (perCost + indexingCost) * length;
    }
    return perCost * length;
  }
  return undefined;
};

export const maxBulkGenerateAmount = 80;

export const maxInfoBaseAmount = 40;

export const sentryIgnoreErrors = [
  /TypeError: Cannot read properties of undefined (reading 'then')/,
  /limit reached/,
  /ChunkLoadError/,
  /Loading CSS chunk/,
  /ResizeObserver loop/,
];

const apiAppUrls = {
  dev: process.env.NEXT_PUBLIC_SITE_URL,
  prod: "https://api-v1.junia.ai",
};

export const apiAppUrl = apiAppUrls[process.env.NEXT_PUBLIC_ENV_NAME];

let userInShopify = false;

export const setUserInShopify = (value: boolean) => {
  userInShopify = value;
};

export const getUserInShopify = () => {
  return userInShopify;
};

export {
  calculateBulkGenerateCosts,
  defaultCollectionName,
  custom,
  markdownFileName,
  defaultPostName,
};

export const maxTeamSize = 3;
