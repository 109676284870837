import { WhiteLogo } from "components/logo";
import { AppBar, Toolbar } from "components/ui/app-bar";
import { Box } from "components/ui/box";
import { Button, IconButton } from "components/ui/button";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { headerHeight, MainBackgroundWrapper } from "./shared";
import { useUser } from "hooks/use-user";
import {
  dashboardRouteConfig,
  serverRoutes,
} from "client-server-shared/config/routes";
import { NextLink } from "components/next-link";
import { useTheme } from "@mui/material/styles";
import { Container } from "../marketing-pages/front-page/shared";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { Typography } from "components/ui/Typography";
import { Collapse } from "components/ui/animation/collapse";
import { useTranslation } from "components/translate";
import Submenu from "./submenu";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";

const usePages = () => {
  const { t } = useTranslation("common");
  const pages = [
    {
      title: "Bulk Content Generator",
      to: serverRoutes.bulkContentCreation,
    },
    {
      title: t("Brand Voice"),
      to: serverRoutes.styleMatch,
    },
    {
      title: t("AI Text Editor"),
      to: serverRoutes.editor,
    },
    {
      title: t("Chat"),
      to: serverRoutes.chat,
    },
    {
      title: t("Templates"),
      to: serverRoutes.templates,
    },
    {
      title: t("Art"),
      to: serverRoutes.art,
    },
    {
      title: t("Blogs"),
      to: serverRoutes.blog,
    },
    {
      title: t("Pricing"),
      to: serverRoutes.pricing,
    },
  ];
  return pages;
};

const usePagesV2 = () => {
  const { t } = useTranslation("common");
  const pages = [
    {
      title: "Bulk Content Generator",
      to: serverRoutes.bulkContentCreation,
    },
    {
      title: t("Brand Voice"),
      to: serverRoutes.styleMatch,
    },
    {
      title: t("AI Text Editor"),
      to: serverRoutes.editor,
    },
    {
      title: "Templates",
      to: serverRoutes.templates,
      /*
      items: [
        {
          title: "Chat",
          to: serverRoutes.chat,
          icon: ChatBubbleOutlineOutlinedIcon,
        },
        {
          title: "Templates",
          icon: LayersOutlinedIcon,
          to: serverRoutes.templates,
        },
      ],
      */
    },

    {
      title: t("Resources"),
      items: [
        {
          icon: BookOutlinedIcon,
          title: t("Blogs"),
          to: serverRoutes.blog,
        },
        {
          icon: SchoolOutlinedIcon,
          title: t("Guides"),
          to: serverRoutes.docs,
        },
        {
          icon: VideoLibraryOutlinedIcon,
          title: t("Video Tutorials"),
          to: "https://www.youtube.com/@JuniaAI",
          linkProps: {
            target: "_blank",
            rel: "noopener noreferrer nofollow",
          },
        },
        {
          icon: BoltOutlinedIcon,
          title: t("Contact"),
          to: serverRoutes.contact,
        },
      ],
    },
    {
      title: t("Pricing"),
      to: serverRoutes.pricing,
    },
  ];
  return pages;
};

interface HeaderLayoutContextValues {
  mobileDrawerOpen: boolean;
  toggleMobileDrawer: () => void;
  closeMobileDrawer: () => void;
}

const HeaderLayoutContext =
  React.createContext<HeaderLayoutContextValues | null>(null);

const useHeaderLayoutContext = () => React.useContext(HeaderLayoutContext)!;

const HeaderLayoutContextProvider = ({ children }) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const toggleMobileDrawer = React.useCallback(() => {
    setMobileDrawerOpen((previous) => !previous);
  }, []);
  const closeMobileDrawer = React.useCallback(() => {
    setMobileDrawerOpen(false);
  }, []);
  const values = {
    mobileDrawerOpen,
    toggleMobileDrawer,
    closeMobileDrawer,
  };
  return (
    <HeaderLayoutContext.Provider value={values}>
      {children}
    </HeaderLayoutContext.Provider>
  );
};

const LogoButtons = () => {
  const { closeMobileDrawer } = useHeaderLayoutContext();
  return (
    <>
      <IconButton
        component={NextLink}
        href="/"
        onClick={closeMobileDrawer}
        sx={{
          display: { xs: "none", md: "flex" },
          mr: 3,
        }}
      >
        <WhiteLogo />
      </IconButton>
      <IconButton
        component={NextLink}
        href="/"
        onClick={closeMobileDrawer}
        sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
      >
        <WhiteLogo height={30} width={120} />
      </IconButton>
    </>
  );
};

const MobileDrawer = () => {
  const theme = useTheme();
  const { toggleMobileDrawer, mobileDrawerOpen, closeMobileDrawer } =
    useHeaderLayoutContext();
  const pages = usePages();
  const user = useUser();
  const { t } = useTranslation("common");
  return (
    <Box
      aria-hidden={!mobileDrawerOpen ? "true" : "false"}
      id="menu-appbar"
      sx={{
        zIndex: 99999,
        width: "100%",
        backgroundColor: "rgb(40, 51, 75, 0.989)",
        top: headerHeight + 8,
        left: 0,
        borderRadius: "22px",
        display: { xs: "flex", md: "none", lg: "none" },
        position: "absolute",
      }}
    >
      <Collapse
        in={mobileDrawerOpen}
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "flex-start",
            rowGap: "24px",
            padding: "12px 24px",
          }}
        >
          {pages.map((page) => (
            <Button
              capitalize
              key={page.title}
              component={NextLink}
              href={page.to}
              onClick={closeMobileDrawer}
              sx={{
                display: "flex",
                textAlign: "center",
                color: "text.white",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid #505C76",
                paddingBottom: "12px",
                borderRadius: 0,
              }}
            >
              <Typography variant="body2">{page.title}</Typography>
              <KeyboardArrowRightOutlinedIcon />
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            columnGap: "12px",
            padding: "24px 0",
          }}
        >
          <Button
            component={NextLink}
            capitalize
            onClick={closeMobileDrawer}
            sx={{
              color: "#D4DCE5",
              fontSize: "14px",
              border: "3px solid #505C76",
              borderRadius: "50px",
              padding: "6px 20px",
            }}
            href={user ? dashboardRouteConfig.dashboard : serverRoutes.login}
          >
            {user ? t("Dashboard") : t("Login")}
          </Button>
          {user ? null : (
            <IconButton
              component={NextLink}
              href={serverRoutes.register}
              onClick={closeMobileDrawer}
              sx={{
                display: "flex",
                color: "text.white",
                borderRadius: "50px",
                border: "3px solid #B354EA",
                fontSize: "14px",
                padding: "6px 12px 6px 15px",
                fontWeight: 600,
                background: "rgba(145, 60, 211, 0.69)",
                "&:hover": {
                  opacity: 0.8,
                  background: "rgba(145, 60, 211, 0.69)",
                },
              }}
            >
              {t("Get Started")} <KeyboardDoubleArrowRightOutlinedIcon />
            </IconButton>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

const MenuControl = () => {
  const { toggleMobileDrawer, mobileDrawerOpen } = useHeaderLayoutContext();

  return (
    <IconButton
      size="large"
      sx={{
        background: mobileDrawerOpen
          ? "linear-gradient(90.16deg, rgb(140, 53, 187) 0.23%, rgba(136, 35, 165, 0.71) 43.79%, rgba(150, 26, 144, 0.67) 63.07%, rgb(217, 69, 176) 101.59%)"
          : "transparent",
        borderRadius: "5px",
        display: { xs: "flex", md: "none", lg: "none", position: "relative" },
      }}
      aria-label="Open menu"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={toggleMobileDrawer}
      color="inherit"
    >
      <MenuIcon />
    </IconButton>
  );
};

const Header = () => {
  const user = useUser();
  const pages = usePages();
  const v2Pages = usePagesV2();
  const { t } = useTranslation("common");

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <HeaderLayoutContextProvider>
      <MainBackgroundWrapper
        sx={
          {
            //  boxShadow: "none",
          }
        }
        component={AppBar}
        position="sticky"
      >
        <Container
          maxWidth="xl"
          sx={{
            /*
            marginTop: "24px",
            borderRadius: "22px",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              */
            height: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              lg: "72px",
              xl: "72px",
            },
            padding: {
              xs: "2px 16px",
              sm: "2px 16px",
              md: "2px 16px",
              lg: "2px 16px",
              xl: "2px 0",
            },
          }}
        >
          <Toolbar
            disableGutters
            sx={
              {
                //    padding: "0 20px",
              }
            }
          >
            <MenuControl />
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <LogoButtons />
              <Box
                component="nav"
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  columnGap: "10px",
                }}
              >
                {v2Pages.map((page) => {
                  if (page.items && page.items.length > 0) {
                    return <Submenu {...page} index={page.title} />;
                  }
                  return (
                    <Button
                      capitalize
                      key={page.title}
                      component={NextLink}
                      href={page.to}
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        textAlign: "center",
                        color: "#D4DCE5",
                        display: "block",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {page.title}
                    </Button>
                  );
                })}
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                marginLeft: "auto",
                display: {
                  xs: "none",
                  md: "flex",
                },
                alignItems: "center",
                columnGap: "12px",
              }}
            >
              <Button
                component={NextLink}
                capitalize
                sx={{
                  color: "#D4DCE5",
                  fontSize: "14px",
                }}
                href={
                  user ? dashboardRouteConfig.dashboard : serverRoutes.login
                }
              >
                {user ? t("Dashboard") : t("Login")}
              </Button>
              {user ? null : (
                <IconButton
                  component={NextLink}
                  href={serverRoutes.register}
                  sx={{
                    display: "flex",
                    color: "text.white",
                    borderRadius: "50px",
                    //  border: "3px solid #B354EA",
                    fontSize: "14px",
                    padding: "12px 12px 12px 15px",
                    fontWeight: 600,
                    background: "rgba(145, 60, 211, 0.69)",
                    "&:hover": {
                      opacity: 0.8,
                      background: "rgba(145, 60, 211, 0.69)",
                    },
                  }}
                >
                  {t("Get Started")} <KeyboardDoubleArrowRightOutlinedIcon />
                </IconButton>
              )}
            </Box>
            {/* 
                <Box
                sx={{
                  flexGrow: 1,
                  marginLeft: "auto",
                  display: { xs: "flex", md: "none" },
                }}
              >
                <MenuControl />
              </Box>
        */}
          </Toolbar>
          <MobileDrawer />
        </Container>
      </MainBackgroundWrapper>
    </HeaderLayoutContextProvider>
  );
};

export default Header;
